import { SupportedWordsSlugs } from "./_bynaryMappings"

const wordSupportedMeasures = 
    [
        "running", 
        "command", 
        "direction", 
        "sync", 
        'valvePosition',
        'key1Inserted',
        'key2Inserted',
        'key3Inserted',
        'key4Inserted',
        'key5Inserted',
        'key6Inserted',
        'key7Inserted',
        'key8Inserted',
        'key9Inserted',
        'key10Inserted',
        'key11Inserted',
        'key12Inserted',
        'key13Inserted',
        'key14Inserted',
    ] as const
type WordSupportedMeasures = typeof wordSupportedMeasures[number]

const additionalSupportedMeasures = [
    "amperage",
    "rotorSpeed",
    "waterFlow",
    "materialTemperature",
    "pressorThrust",
    "rotorSpeed",
    "program",
    "bufferLoadedWithF015",
    "bufferLoadedWithF070"
] as const
type AdditionalSupportedMeasures = typeof additionalSupportedMeasures[number]

type SupportedMeasures = WordSupportedMeasures | AdditionalSupportedMeasures

const supportedMeasuresByWord: Record<SupportedWordsSlugs, WordSupportedMeasures[]> = {
    MDIR : ["command", "running"],
    INV: ["command", "direction"],
    SYNC: ["sync"],
    KH: ["valvePosition"],
    PKEY: [
        "key1Inserted",
        "key2Inserted",
        "key3Inserted",
        "key4Inserted",
        "key5Inserted",
        "key6Inserted",
        "key7Inserted",
        "key8Inserted",
        "key9Inserted",
        "key10Inserted",
        "key11Inserted",
        "key12Inserted",
        "key13Inserted",
        "key14Inserted",
    ]
}

export type {WordSupportedMeasures, AdditionalSupportedMeasures, SupportedMeasures}
export {supportedMeasuresByWord}