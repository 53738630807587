import * as React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { COLORS } from '../_constants'

interface OwnProps {
  hideTitle?: boolean
  hideZero?: boolean
  labels: string[]
  refLabel?: string | number | null
  row?: boolean
  showTooltip?: { [k: string]: string }
}

type Props = OwnProps & WithTranslation

interface LabelWithColor {
  color: Record<number,string>
  label: string
}

class GenericBottomLegend extends React.Component<Props> {
  public render() {

    const labelsWithoutLegendDuplicates = this.props.labels.reduce((acc: LabelWithColor[], value) => {
      if (acc.find(a => Object.is(a.color, COLORS[value]))) {
        return [...acc]
      }
      return [...acc, { label: value, color: COLORS[value] }]
    }, [])
    
    return (
      <div className={'row row-legend row-legend-bottom ' + (this.props.row ? 'row-legend-row' : '')}>
        <div className="col-12">
          {this.props.labels &&
            labelsWithoutLegendDuplicates.map((legend: LabelWithColor, id: number) => {
              const hasTooltip = this.props.showTooltip && Object.keys(this.props.showTooltip).indexOf(legend.label) > -1
              return (
                legend.color && (
                  <React.Fragment key={id}>
                    {!this.props.hideTitle && <h3>{this.props.t('plantAnalysis.legendTitles.' + legend.label)}</h3>}
                    <ul>
                      {Object.keys(legend.color).map((name: string , index: number) => (
                        (
                          !this.props.hideZero ||
                          (this.props.hideZero && name !== "0")
                        ) && <li
                          key={index}
                          title={hasTooltip ? this.props.t(this.props.showTooltip![legend.label] + name) : name}
                        >
                          <span
                            className="label-color label-color-square"
                            style={{ backgroundColor: legend.color[name] }}
                          />
                          <span>
                            {this.props.t('plantAnalysis.legend.' + legend.label + '.' + name) + (this.props.refLabel || '')}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </React.Fragment>
                )
              )
            })}
        </div>
      </div>
    )
  }
}

export default withTranslation()(GenericBottomLegend)
