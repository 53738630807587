import { WordSupportedMeasures } from "./_measures"

const baseWordMeasures = [
    "manual", 
    "automatic", 
    "local", 
    "sectioned", 
    "running", 
    "forward", 
    "backward", 
    "sync", 
    'valveOpenPos', 
    'valveClosedPos',
    'key1Inserted',
    'key2Inserted',
    'key3Inserted',
    'key4Inserted',
    'key5Inserted',
    'key6Inserted',
    'key7Inserted',
    'key8Inserted',
    'key9Inserted',
    'key10Inserted',
    'key11Inserted',
    'key12Inserted',
    'key13Inserted',
    'key14Inserted'
] as const
type BaseWordMeasures = typeof baseWordMeasures[number]

const supportedWordsSlugs = ["MDIR", "INV", "SYNC", 'KH', "PKEY"] as const
type SupportedWordsSlugs = typeof supportedWordsSlugs[number]

const derivedWordMeasures: Readonly<WordSupportedMeasures[]> = ["direction", "command",'valvePosition'] as const
type DerivedWordMeasures = typeof derivedWordMeasures[number]

const baseMeasuresForDerivedMeasures: {[key in DerivedWordMeasures]?: BaseWordMeasures[]} = {
    "direction" : ["forward", "backward"],
    "command": ["automatic", "manual", "local", "sectioned"],
    'valvePosition': ['valveOpenPos', 'valveClosedPos']
}

const binaryMappingFromWordSlug: {[slug in SupportedWordsSlugs]: {[key in BaseWordMeasures]? : number}} = {
    "MDIR": {
        manual: 0,
        automatic: 1,
        local: 2,
        running: 6,
    },
    "INV": {
        manual: 0,
        automatic: 1,
        local: 2,
        sectioned: 3,
        forward: 6,
        backward: 7,
    },
    "SYNC" : {
        sync: 6
    },
    'KH': {
        valveOpenPos: 10,
        valveClosedPos: 11,
    },
    "PKEY": {
        key1Inserted: 0,
        key2Inserted: 1,
        key3Inserted: 2,
        key4Inserted: 3,
        key5Inserted: 4,
        key6Inserted: 5,
        key7Inserted: 6,
        key8Inserted: 7,
        key9Inserted: 8,
        key10Inserted: 9,
        key11Inserted: 10,
        key12Inserted: 11,
        key13Inserted: 12,
        key14Inserted: 14,
    }
}

const getBitValue = (number: number, position: number) : number => {
    const binaryString = number.toString(2).padStart(16, '0');
    const bitIndex = binaryString.length - position - 1;
    if (bitIndex >= 0 && bitIndex < binaryString.length) {
        return parseInt(binaryString.charAt(bitIndex), 10);
    } else {
        return 0;
    }
}

export const getWordValueFromBinaryMapping = (key: BaseWordMeasures, wordSlug: SupportedWordsSlugs, wordValue: number): number | undefined => {
    const binaryMapping = binaryMappingFromWordSlug[wordSlug]
    const bitPosition = binaryMapping[key]
    if(bitPosition === undefined){
        return undefined
    }
    const value = getBitValue(wordValue, bitPosition)
    return value
  }

export type {SupportedWordsSlugs, BaseWordMeasures, DerivedWordMeasures}
export { binaryMappingFromWordSlug, baseMeasuresForDerivedMeasures as binaryFieldsForWordMeasure, derivedWordMeasures, supportedWordsSlugs }