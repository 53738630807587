import * as React from 'react'
import * as moment from 'moment'
import { RouteComponentProps, withRouter } from 'react-router'
import { withTranslation, WithTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons/faCircleNotch'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle'
import { faEyeDropper } from '@fortawesome/free-solid-svg-icons/faEyeDropper'
import { faHourglassHalf } from '@fortawesome/free-solid-svg-icons/faHourglassHalf'
import { Workshift } from '@mv-submodules/inplant-plantanalysis-fe-iblu/types/workshift'
import { GeneralData } from '@mv-submodules/inplant-plantanalysis-fe-iblu/types/measure'
import { generalFetchData } from '@mv-submodules/inplant-plantanalysis-fe-iblu/redux/actions/general'
import {
  resetWorkshiftFilter,
  updateDateFilter,
  updateWorkshiftFilter,
} from '@mv-submodules/inplant-plantanalysis-fe-iblu/redux/actions/common'
import { modelFetchData } from '@mv-submodules/inplant-plantanalysis-fe-iblu/redux/actions/model'
import { flatData } from '@mv-submodules/inplant-plantanalysis-fe-iblu/functions/model'
import WorkshiftSelector from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/WorkShiftSelector/WorkshiftSelector'
import GraphWrapper from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/GraphWrapper/GraphWrapperView'
import GeneralGraphs from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/GeneralGraphs/GeneralGraphsView'
import { ModelNodeData } from '@mv-submodules/inplant-plantanalysis-fe-iblu/types/chronograph'
import BeltGraphs from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/BeltGraph/BeltGraphs'
import BunkerGraphs from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/BunkerGraph/BunkerGraphs'
import FanGraphs from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/FanGraph/FanGraphs'
import BagGraphs from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/BagGraph/BagGraphs'
import MultiSeriesLineCharts from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/MultiSeriesLineCharts/MultiSeriesLineCharts'
import Row from '@mv-submodules/inplant-components-fe/ui/components/Grid/Row'
import Column from '@mv-submodules/inplant-components-fe/ui/components/Grid/Column'
import BeltBinarySrcGraphs, {
  materialTemperatureAdditionalLine,
  waterFlowAdditionalLine,
} from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/BeltGraph/BeltBinarySrcGraphs'
import BeltBinarySrcGenGraphs from '@mv-submodules/inplant-plantanalysis-fe-iblu/ui/components/widgets/BeltGraph/BeltBinarySrcGenGraphs'
// import BufferFillDetail from '../../widgets/Vecoplant/BufferFill/BufferFillDetail'
import { PlantAnalysisDetailsConfigurationExtended } from '../../../../types/settings'
import PressDetail from '../../widgets/Press/PressDetail'
import AspirationSystem from '../../widgets/AspirationSystem/AspirationSystem'
import CompressorChart from '../../widgets/CompressorChart/CompressorChart'
import GenericBinarySrcGraph from '../../widgets/GeneralBinarySrcGraph/GenericBinarySrcGraph'
import { BuffersFillGraph } from '../../widgets/Buffers'
import { supportedWordsSlugs } from '../../widgets/GeneralBinarySrcGraph/_measures/_bynaryMappings'

library.add(faCircleNotch, faInfoCircle, faEyeDropper, faHourglassHalf)

let ticking = false

interface OwnState {
  startDate?: moment.Moment
  endDate?: moment.Moment
  currentGraph: string[]
  lastScrollY: number
}

interface StateProps {
  detailsConfiguration: PlantAnalysisDetailsConfigurationExtended | null
  dateFilterStart: moment.Moment
  workshift: Workshift | null
  lavorazioneAttuale: null | GeneralData
  ricettaAttuale: null | GeneralData
  plant: any | null
  model: null | GeneralData
  chartConfigurations: any | null
  workshifts: null | GeneralData
}

const mapStateToProps = (state: any): StateProps => ({
  detailsConfiguration: {
    'ric-sg': {
      MDIR: {
        binaryMapping: {
          manuale: 1,
          automatico: 2,
          locale: 4,
          sezionato: 8,
          running: 64,
          avanti: 0,
          bypass: 0,
          indietro: 0,
          inverter: 0,
          marcia: 0,
        },
      },
      INV: {
        binaryMapping: {
          manuale: 1,
          automatico: 2,
          locale: 4,
          sezionato: 8,
          avanti: 64,
          indietro: 128,
          bypass: 0,
          inverter: 0,
          marcia: 0,
          running: 0,
        },
      },
      DIR: {
        binaryMapping: {
          manuale: 1,
          automatico: 2,
          locale: 4,
          sezionato: 8,
          avanti: 64,
          indietro: 128,
          bypass: 0,
          inverter: 0,
          marcia: 0,
          running: 0,
        },
      },
      default: {
        binaryMapping: {
          manuale: 1,
          automatico: 2,
          locale: 4,
          sezionato: 8,
          avanti: 64,
          indietro: 128,
          bypass: 0,
          inverter: 0,
          marcia: 0,
          running: 64,
        },
      },
    },
  },
  /* state.config?.plantAnalysis?.detailsConfiguration && isJSON(state.config.plantAnalysis.detailsConfiguration)
      ? JSON.parse(state.config.plantAnalysis.detailsConfiguration)
      : undefined */ dateFilterStart:
    state.plantAnalysis.common.dateFilterStart,
  workshift: state.plantAnalysis.common.workshift,
  lavorazioneAttuale: state.plantAnalysis.general.lavorazione,
  ricettaAttuale: state.plantAnalysis.general.ricetta,
  plant: state.plantSelector || null,
  model: state.plantAnalysis.model,
  chartConfigurations: state.config && state.config.plantAnalysis && state.config.plantAnalysis.chartConfigurations,
  workshifts: state.plantAnalysis.workshifts.workshifts,
})

interface DispatchProps {
  generalFetchData: (workshift: Workshift) => void
  resetWorkshift: () => void
  modelFetchData: (plant?: string) => void
  updateWorkshiftFilter: (workshift: number, workshifts: GeneralData | null) => void
  updateDateFilter: (dateStart: moment.Moment, dateEnd: moment.Moment, isManual?: boolean) => void
}

const mapDispatchToProps = (dispatch: Function): DispatchProps => {
  return {
    generalFetchData: (workshift: Workshift) => dispatch(generalFetchData(workshift)),
    resetWorkshift: (workshift?: Workshift) => dispatch(resetWorkshiftFilter(workshift)),
    modelFetchData: (plant?: string) => dispatch(modelFetchData(plant)),
    updateWorkshiftFilter: (workshift: number, workshifts: GeneralData | null) =>
      dispatch(updateWorkshiftFilter(workshift, workshifts)),
    updateDateFilter: (dateStart, dateEnd, isManual) => dispatch(updateDateFilter(dateStart, dateEnd, isManual)),
  }
}

export type Props = WithTranslation & RouteComponentProps<any> & DispatchProps & StateProps

const itemRefs: any[] = []

class PlantAnalysis extends React.Component<Props, OwnState> {
  // @ts-ignore
  private selectDateText: string
  // @ts-ignore
  private mounted: boolean

  constructor(props: Props) {
    super(props)

    this.selectDateText = this.props.t('plantAnalysis.actions.selectDateText')

    this.state = {
      startDate: moment(this.props.dateFilterStart),
      endDate: moment(this.props.dateFilterStart),
      currentGraph: ['M001'],
      lastScrollY: 0,
    }

    this.toggleCollapse = this.toggleCollapse.bind(this)
    this.handleScroll = this.handleScroll.bind(this)
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (
      (nextProps.dateFilterStart !== this.props.dateFilterStart || nextProps.workshift !== this.props.workshift) &&
      nextProps.workshift &&
      moment.isMoment(nextProps.dateFilterStart)
    ) {
      this.props.generalFetchData(nextProps.workshift)
    }

    if (nextProps.plant.plant !== this.props.plant.plant && !this.props.plant.isLoading) {
      this.props.modelFetchData(nextProps.plant.plant)
    }
  }

  public componentDidMount() {
    this.mounted = true
    window.addEventListener('scroll', this.handleScroll)
    /****/
    const url = new URL(window.location.href)
    const manualDate = url.searchParams.get('manualDate')
    // const manualWorkshift = url.searchParams.get('manualWorkshift')
    if (manualDate && moment(manualDate).isValid()) {
      this.props.updateDateFilter(moment(manualDate), moment(manualDate), true)
    }
    /****/

    if (this.props.dateFilterStart && this.props.workshift && this.props.plant) {
      this.props.generalFetchData(this.props.workshift)
    }

    if (this.props.plant && this.props.plant.plant && !this.props.plant.isLoading) {
      this.props.modelFetchData(this.props.plant.plant)
    }
  }

  public componentWillUnmount() {
    this.mounted = false
    window.removeEventListener('scroll', this.handleScroll)
  }

  public render() {
    const { workshift, model, t, plant, chartConfigurations } = this.props
    const { currentGraph } = this.state

    const nodes = model && !model.fetching && !model.error && model.data ? flatData(model.data.model.content) : []

    const timeStart = (workshift && workshift.start && moment(workshift.start).format('HH:mm')) || null
    const timeEnd =
      (workshift &&
        workshift.end &&
        workshift.start &&
        workshift.start !== workshift.end &&
        moment(workshift.end).format('HH:mm')) ||
      null
    const notEnded = workshift && !workshift.end
    const running =
      workshift &&
      workshift.end &&
      timeEnd &&
      moment(workshift.end).isSame(moment.now(), 'day') &&
      timeEnd === moment().format('HH:mm')

    const url = new URL(window.location.href)
    const allOpened = url.searchParams.get('allOpened')

    return (
      <div
        className={'mv4iot-fe-plant-analysis plant-analysis-list' + (this.state.lastScrollY > 48 ? 'is-scrolling' : '')}
      >
        <header>
          <div className={'flex-row justify-content-between'}>
            <Row horizontalAlignment={'between'} spacing={{ horizontal: false, vertical: false }}>
              <Column md={10}>
                <h1 className="title mb-3">{t('plantAnalysis.navigation.plantAnalysis')}</h1>
                <ul className="top-details">
                  <li>
                    <span className="label black">{t('plantAnalysis.labels.plantH')}</span> {workshift && workshift.h}
                  </li>
                  <li>
                    <span className="label">{t('plantAnalysis.labels.workshift.start')}</span> {timeStart}
                  </li>
                  <li>
                    <span className="label">{t('plantAnalysis.labels.workshift.end')}</span>
                    {running
                      ? t('plantAnalysis.labels.workshift.running')
                      : notEnded
                      ? t('plantAnalysis.labels.workshift.notEnded')
                      : timeEnd}
                  </li>
                </ul>
              </Column>
              <Column md={2}>
                <Row horizontalAlignment={'end'}>
                  <WorkshiftSelector isRenderVisible={true} />
                </Row>
              </Column>
            </Row>
          </div>
        </header>
        <div className="content">
          <div id="container">
            <React.Fragment>
              {model && !model.fetching && (!nodes || nodes.length === 0) ? (
                <Row horizontalAlignment={'center'}>
                  <Column sm={6}>
                    <div className="alert alert-danger w-100">{t('plantAnalysis.noNodes')}</div>
                  </Column>
                </Row>
              ) : !['ro', 'ric-sg'].includes(plant.plant) ? (
                // @ts-ignore
                <div className="graph-wrapper" ref={e => (itemRefs.general = e)}>
                  <GraphWrapper
                    active={currentGraph}
                    component={{ id: 'general', label: t('plantAnalysis.labels.general') }}
                    toggleCollapse={this.toggleCollapse}
                    subtitle={'graph2.subTitle'}
                  >
                    <GeneralGraphs
                      component={{ id: 'general', label: 'general' }}
                      active={currentGraph}
                      toggleCollapse={this.toggleCollapse}
                      forceFetch={true}
                    />
                  </GraphWrapper>
                </div>
              ) : (
                false
              )}
              {nodes
                // components
                .sort((a: ModelNodeData, b: ModelNodeData) => {
                  if (a.code < b.code) {
                    return -1
                  }
                  if (a.code > b.code) {
                    return 1
                  }
                  return 0
                })
                .map((c: any) => {
                  const nodeTypeSplit = c.nodeType.split('-')
                  const baseNodeType = nodeTypeSplit[0]
                  const decoratorNodeType = nodeTypeSplit[1]
                  const nodeAdditionalMeasures = nodeTypeSplit[2]

                  switch (baseNodeType) {
                    case 'PLANT_DATA_ROT_SCR':
                    case 'PLANT_DATA_BELT':
                      return (
                        <div className="graph-wrapper" ref={e => (itemRefs[c.id] = e)} key={c.id}>
                          <GraphWrapper
                            active={currentGraph}
                            component={c}
                            toggleCollapse={this.toggleCollapse}
                            subtitle={''}
                          >
                            <BeltGraphs t={true} component={c} inverter={false} />
                          </GraphWrapper>
                        </div>
                      )
                    case 'PLANT_DATA_ROT_SCR_INVERTER':
                    case 'PLANT_DATA_BELT_INVERTER':
                      return (
                        <div className="graph-wrapper" ref={e => (itemRefs[c.id] = e)} key={c.id}>
                          <GraphWrapper
                            active={currentGraph}
                            component={c}
                            toggleCollapse={this.toggleCollapse}
                            subtitle={'graph2.subTitle'}
                          >
                            <BeltGraphs t={true} component={c} inverter={true} />
                          </GraphWrapper>
                        </div>
                      )
                    case 'PLANT_DATA_BNK':
                      return (
                        <div className="graph-wrapper" ref={e => (itemRefs[c.id] = e)} key={c.id}>
                          <GraphWrapper
                            active={currentGraph}
                            component={c}
                            toggleCollapse={this.toggleCollapse}
                            subtitle={'graph3.subTitle'}
                          >
                            <BunkerGraphs t={true} component={c} inverter={false} />
                          </GraphWrapper>
                        </div>
                      )
                    case 'PLANT_DATA_BNK_INVERTER':
                      return (
                        <div className="graph-wrapper" ref={e => (itemRefs[c.id] = e)} key={c.id}>
                          <GraphWrapper
                            active={currentGraph}
                            component={c}
                            toggleCollapse={this.toggleCollapse}
                            subtitle={'graph3.subTitle'}
                          >
                            <BunkerGraphs t={true} component={c} inverter={true} />
                          </GraphWrapper>
                        </div>
                      )
                    case 'PLANT_DATA_ASP_INVERTER':
                      return (
                        <div className="graph-wrapper" ref={e => (itemRefs[c.id] = e)} key={c.id}>
                          <GraphWrapper
                            active={currentGraph}
                            component={c}
                            toggleCollapse={this.toggleCollapse}
                            subtitle={'graph4.subTitle'}
                          >
                            <FanGraphs t={true} component={c} inverter={true} />
                          </GraphWrapper>
                        </div>
                      )
                    case 'PLANT_DATA_ASP':
                      return (
                        <div className="graph-wrapper" ref={e => (itemRefs[c.id] = e)} key={c.id}>
                          <GraphWrapper
                            active={currentGraph}
                            component={c}
                            toggleCollapse={this.toggleCollapse}
                            subtitle={'graph4.subTitle'}
                          >
                            <FanGraphs t={true} component={c} />
                          </GraphWrapper>
                        </div>
                      )
                    case 'PLANT_DATA_BRT':
                      return (
                        <div className="graph-wrapper" ref={e => (itemRefs[c.id] = e)} key={c.id}>
                          <GraphWrapper active={currentGraph} component={c} toggleCollapse={this.toggleCollapse}>
                            <BagGraphs t={true} component={c} />
                          </GraphWrapper>
                        </div>
                      )
                    case 'PLANT_DATA_BELT_LOADER':
                    case 'PLANT_DATA_DEC':
                      return (
                        <div className="graph-wrapper" ref={e => (itemRefs[c.id] = e)} key={c.id}>
                          <GraphWrapper
                            active={currentGraph}
                            component={c}
                            toggleCollapse={this.toggleCollapse}
                            subtitle={'graph4.subTitle'}
                            zeroPadding={true}
                            manuallyOpened={!!(allOpened && allOpened === '1')}
                          >
                            <MultiSeriesLineCharts
                              component={c}
                              measures={
                                chartConfigurations &&
                                plant &&
                                chartConfigurations[plant.plant] &&
                                chartConfigurations[plant.plant][c.id]
                              }
                            />
                          </GraphWrapper>
                        </div>
                      )
                    /** BLUEAIR */
                    case 'SG2_PLANT_CHART': {
                      const getBinaryWords = () => {
                        if (supportedWordsSlugs.includes(decoratorNodeType)) {
                          return [{ name: 'WORD01_INFO', type: decoratorNodeType }]
                        }
                        return []
                      }

                      const getMeasures = () => {
                        const measures = [
                          {
                            name: 'amperage',
                            slug: 'ACT_CUR',
                            unitOfMeasure: 'A',
                            representation: 'line',
                          },
                        ]
                        if (nodeAdditionalMeasures) {
                          if (nodeAdditionalMeasures.includes('SPD')) {
                            measures.push({
                              name: 'driveSpeed',
                              slug: 'SPD_ACT',
                              unitOfMeasure: '%',
                              representation: 'line',
                            })
                          }
                          if (nodeAdditionalMeasures.includes('PRS')) {
                            measures.push({
                              name: 'pressure',
                              slug: 'ACT_PRS',
                              unitOfMeasure: 'bar',
                              representation: 'line',
                            })
                          }
                          if (nodeAdditionalMeasures.includes('MTMP')) {
                            measures.push({
                              name: 'materialTemp',
                              slug: 'MAT_ACT_TMP',
                              unitOfMeasure: '°C',
                              representation: 'line',
                            })
                          }
                        }

                        return measures
                      }

                      return (
                        <div className="graph-wrapper" ref={e => (itemRefs[c.id] = e)} key={c.id}>
                          <GraphWrapper active={currentGraph} component={c} toggleCollapse={this.toggleCollapse}>
                            <GenericBinarySrcGraph
                              t={true}
                              id={c.id}
                              binaryWords={getBinaryWords()}
                              measures={getMeasures()}
                            />
                          </GraphWrapper>
                        </div>
                      )
                    }
                    case 'SG2_PLANT_KEYS': {
                      return (
                        <div className="graph-wrapper" ref={e => (itemRefs[c.id] = e)} key={c.id}>
                          <GraphWrapper active={currentGraph} component={c} toggleCollapse={this.toggleCollapse}>
                            <GenericBinarySrcGraph
                              t={true}
                              id={c.id}
                              binaryWords={[{ name: 'WORD01_INFO', type: 'PKEY' }]}
                            />
                          </GraphWrapper>
                        </div>
                      )
                    }
                    case 'SG2_PLANT_DATA_SCREW_CUR': {
                      return (
                        <div className="graph-wrapper" ref={e => (itemRefs[c.id] = e)} key={c.id}>
                          <GraphWrapper active={currentGraph} component={c} toggleCollapse={this.toggleCollapse}>
                            <GenericBinarySrcGraph
                              t={true}
                              id={c.id}
                              warnAreas={[
                                { slug: 'runningWarn', measure: 'running', fn: (value: number) => value === 0 },
                              ]}
                              binaryWords={[{ name: 'WORD01_INFO', type: 'MDIR' }]}
                              measures={[
                                {
                                  name: 'amperage',
                                  slug: 'ACT_CUR',
                                  unitOfMeasure: 'A',
                                  representation: 'line',
                                },
                              ]}
                            />
                          </GraphWrapper>
                        </div>
                      )
                    }
                    case 'SG2_PLANT_DATA_SCREW_TEMP': {
                      return (
                        <div className="graph-wrapper" ref={e => (itemRefs[c.id] = e)} key={c.id}>
                          <GraphWrapper active={currentGraph} component={c} toggleCollapse={this.toggleCollapse}>
                            <GenericBinarySrcGraph
                              t={true}
                              id={c.id}
                              warnAreas={[
                                { slug: 'runningWarn', measure: 'running', fn: (value: number) => value === 0 },
                              ]}
                              binaryWords={[{ name: 'WORD01_INFO', type: 'MDIR' }]}
                              measures={[
                                {
                                  name: 'materialTemp',
                                  slug: 'MAT_ACT_TMP',
                                  unitOfMeasure: '°C',
                                  representation: 'line',
                                },
                              ]}
                            />
                          </GraphWrapper>
                        </div>
                      )
                    }
                    case 'SG2_PLANT_DATA_SCREW_CUR_TEMP': {
                      return (
                        <div className="graph-wrapper" ref={e => (itemRefs[c.id] = e)} key={c.id}>
                          <GraphWrapper active={currentGraph} component={c} toggleCollapse={this.toggleCollapse}>
                            <GenericBinarySrcGraph
                              t={true}
                              id={c.id}
                              warnAreas={[
                                { slug: 'runningWarn', measure: 'running', fn: (value: number) => value === 0 },
                              ]}
                              binaryWords={[{ name: 'WORD01_INFO', type: 'MDIR' }]}
                              measures={[
                                {
                                  name: 'amperage',
                                  slug: 'ACT_CUR',
                                  unitOfMeasure: 'A',
                                  representation: 'line',
                                },
                                {
                                  name: 'materialTemp',
                                  slug: 'MAT_ACT_TMP',
                                  unitOfMeasure: '°C',
                                  representation: 'line',
                                },
                              ]}
                            />
                          </GraphWrapper>
                        </div>
                      )
                    }
                    case 'SG2_PLANT_TRITURATORE': {
                      return (
                        <div className="graph-wrapper" ref={e => (itemRefs[c.id] = e)} key={c.id}>
                          <GraphWrapper active={currentGraph} component={c} toggleCollapse={this.toggleCollapse}>
                            <GenericBinarySrcGraph
                              t={true}
                              id={c.id}
                              warnAreas={[
                                { slug: 'runningWarn', measure: 'running', fn: (value: number) => value === 0 },
                              ]}
                              binaryWords={[{ name: 'WORD01_INFO', type: 'MDIR' }]}
                              measures={[
                                {
                                  name: 'amperage',
                                  slug: 'MAIN_DRIVERS_CURRENT',
                                  unitOfMeasure: 'A',
                                  representation: 'line',
                                },
                                {
                                  name: 'rotorSpeed',
                                  slug: 'DRIVE1_SPEED_PERCENT',
                                  unitOfMeasure: '%',
                                  representation: 'line',
                                },
                                {
                                  name: 'pressorThrust',
                                  slug: 'RAM_FEED_PERCENT',
                                  unitOfMeasure: '%',
                                  representation: 'line',
                                },
                              ]}
                            />
                          </GraphWrapper>
                        </div>
                      )
                    }
                    case 'SG2_PLANT_TRITURATORE_UNTHA': {
                      return (
                        <div className="graph-wrapper" ref={e => (itemRefs[c.id] = e)} key={c.id}>
                          <GraphWrapper active={currentGraph} component={c} toggleCollapse={this.toggleCollapse}>
                            <GenericBinarySrcGraph
                              t={true}
                              id={c.id}
                              warnAreas={[
                                { slug: 'runningWarn', measure: 'running', fn: (value: number) => value === 0 },
                              ]}
                              binaryWords={[{ name: 'WORD01_INFO', type: 'MDIR' }]}
                              measures={[
                                {
                                  name: 'amperage',
                                  slug: 'MAIN_DRIVERS_CURRENT',
                                  unitOfMeasure: 'A',
                                  representation: 'line',
                                },
                                { name: 'rotorSpeed', slug: 'ROT_ACT_SPD', unitOfMeasure: '%', representation: 'line' },
                                { name: 'program', slug: 'ACT_PROGRAM', representation: 'time-bar' },
                              ]}
                            />
                          </GraphWrapper>
                        </div>
                      )
                    }
                    case 'SG2_PLANT_BUFFER_SINGLE':
                      return (
                        <div className="graph-wrapper" ref={e => (itemRefs[c.id] = e)} key={c.id}>
                          <GraphWrapper active={currentGraph} component={c} toggleCollapse={this.toggleCollapse}>
                            <GenericBinarySrcGraph
                              t={true}
                              id={c.id}
                              binaryWords={[{ name: 'WORD01_INFO', type: 'SYNC' }]}
                            />
                          </GraphWrapper>
                        </div>
                      )
                    case 'SG2_PLANT_KH_VALVE':
                      return (
                        <div className="graph-wrapper" ref={e => (itemRefs[c.id] = e)} key={c.id}>
                          <GraphWrapper active={currentGraph} component={c} toggleCollapse={this.toggleCollapse}>
                            <GenericBinarySrcGraph
                              t={true}
                              id={c.id}
                              binaryWords={[{ name: 'WORD01_INFO', type: 'KH' }]}
                            />
                          </GraphWrapper>
                        </div>
                      )
                    case 'SG2_PLANT_DATA_BELT_INVERTER_MONO':
                    case 'SG2_PLANT_DATA_BELT_INVERTER':
                    case 'SG2_PLANT_DATA_BELT_MONO':
                    case 'SG2_PLANT_DATA_BELT':
                    case 'SG2_PLANT_DATA_MAT': {
                      const waterFlowIsPresent = ['SG2_PLANT_MULINO'].includes(baseNodeType)
                      const externalMaterialTemperatureIsPresent =
                        ['SG2_PLANT_DATA_MAT'].includes(baseNodeType) &&
                        (decoratorNodeType as string).toLowerCase() !== 'inv'
                      const additionlMaterialTemperatureIsPresent =
                        ['SG2_PLANT_DATA_MAT'].includes(baseNodeType) &&
                        (decoratorNodeType as string).toLowerCase() === 'inv'
                      return (
                        <div className="graph-wrapper" ref={e => (itemRefs[c.id] = e)} key={c.id}>
                          <GraphWrapper
                            active={currentGraph}
                            component={c}
                            toggleCollapse={this.toggleCollapse}
                            subtitle={baseNodeType.indexOf('INVERTER') >= 0 ? 'graph2.subTitle' : null}
                          >
                            <BeltBinarySrcGraphs
                              t={true}
                              binaryConfiguration={
                                this.props.detailsConfiguration?.[this.props.plant.plant ?? '']?.[
                                  decoratorNodeType ?? 'default'
                                ].binaryMapping
                              }
                              component={c}
                              measureActCurrentName={
                                baseNodeType === 'SG2_PLANT_TRITURATORE' ? '_MAIN_DRIVERS_CURRENT' : undefined
                              }
                              mono={[
                                'SG2_PLANT_DATA_BELT_INVERTER_MONO',
                                'SG2_PLANT_DATA_BELT_MONO',
                                'SG2_PLANT_DATA_MAT',
                              ].includes(baseNodeType)}
                              inverter={[
                                'SG2_PLANT_DATA_BELT_INVERTER_MONO',
                                'SG2_PLANT_DATA_BELT_INVERTER',
                                'SG2_PLANT_TRITURATORE',
                                'SG2_PLANT_MULINO',
                              ].includes(baseNodeType)}
                              externalChartLines={[
                                ...(externalMaterialTemperatureIsPresent ? [materialTemperatureAdditionalLine] : []),
                              ]}
                              additionalChartLines={[
                                ...(waterFlowIsPresent ? [waterFlowAdditionalLine] : []),
                                ...(additionlMaterialTemperatureIsPresent ? [materialTemperatureAdditionalLine] : []),
                              ]}
                              hideAvind={['SG2_PLANT_TRITURATORE', 'SG2_PLANT_MULINO'].includes(baseNodeType)}
                              hideSpeed={['SG2_PLANT_TRITURATORE', 'SG2_PLANT_MULINO'].includes(baseNodeType)}
                              subnodeType={decoratorNodeType}
                            />
                          </GraphWrapper>
                        </div>
                      )
                    }
                    case 'SG2_PLANT_MULINO': {
                      return (
                        <div className="graph-wrapper" ref={e => (itemRefs[c.id] = e)} key={c.id}>
                          <GraphWrapper active={currentGraph} component={c} toggleCollapse={this.toggleCollapse}>
                            <GenericBinarySrcGraph
                              t={true}
                              id={c.id}
                              warnAreas={[
                                { slug: 'runningWarn', measure: 'running', fn: (value: number) => value === 0 },
                              ]}
                              binaryWords={[{ name: 'WORD01_INFO', type: 'MDIR' }]}
                              measures={[
                                {
                                  name: 'amperage',
                                  slug: 'ACT_CUR',
                                  unitOfMeasure: 'A',
                                  representation: 'line',
                                },
                                {
                                  name: 'waterFlow',
                                  slug: 'H2O_ACT_FLW',
                                  unitOfMeasure: 'l/h',
                                  representation: 'line',
                                },
                              ]}
                            />
                          </GraphWrapper>
                        </div>
                      )
                    }
                    case 'SG2_PLANT_DATA_PRS': {
                      // const waterFlowIsPresent = ['SG2_PLANT_MULINO'].includes(baseNodeType)
                      // const externalMaterialTemperatureIsPresent =
                      //   ['SG2_PLANT_DATA_MAT'].includes(baseNodeType) &&
                      //   (decoratorNodeType as string).toLowerCase() !== 'inv'
                      // const additionlMaterialTemperatureIsPresent =
                      //   ['SG2_PLANT_DATA_MAT'].includes(baseNodeType) &&
                      //   (decoratorNodeType as string).toLowerCase() === 'inv'
                      return (
                        <div className="graph-wrapper" ref={e => (itemRefs[c.id] = e)} key={c.id}>
                          <GraphWrapper
                            active={currentGraph}
                            component={c}
                            toggleCollapse={this.toggleCollapse}
                            subtitle={baseNodeType.indexOf('INVERTER') >= 0 ? 'graph2.subTitle' : null}
                          >
                            <CompressorChart
                              t={true}
                              binaryConfiguration={
                                this.props.detailsConfiguration?.[this.props.plant.plant ?? '']?.[
                                  decoratorNodeType ?? 'default'
                                ].binaryMapping
                              }
                              component={c}
                              measureActCurrentName={
                                baseNodeType === 'SG2_PLANT_TRITURATORE' ? '_MAIN_DRIVERS_CURRENT' : undefined
                              }
                              mono={[
                                'SG2_PLANT_DATA_BELT_INVERTER_MONO',
                                'SG2_PLANT_DATA_BELT_MONO',
                                'SG2_PLANT_DATA_MAT',
                              ].includes(baseNodeType)}
                              inverter={[
                                'SG2_PLANT_DATA_BELT_INVERTER_MONO',
                                'SG2_PLANT_DATA_BELT_INVERTER',
                                'SG2_PLANT_TRITURATORE',
                                'SG2_PLANT_MULINO',
                              ].includes(baseNodeType)}
                              // additionalChartLines={[
                              //   ...(waterFlowIsPresent ? [waterFlowAdditionalLine] : []),
                              //   ...(additionlMaterialTemperatureIsPresent ? [materialTemperatureAdditionalLine] : []),
                              // ]}
                              // externalChartLines={[
                              //   ...(externalMaterialTemperatureIsPresent ? [materialTemperatureAdditionalLine] : []),
                              // ]}
                              hideAvind={['SG2_PLANT_TRITURATORE', 'SG2_PLANT_MULINO'].includes(baseNodeType)}
                              hideSpeed={['SG2_PLANT_TRITURATORE', 'SG2_PLANT_MULINO'].includes(baseNodeType)}
                              subnodeType={decoratorNodeType}
                            />
                          </GraphWrapper>
                        </div>
                      )
                    }
                    case 'SG2_PLANT_DEC':
                      return (
                        <div className="graph-wrapper" ref={e => (itemRefs[c.id] = e)} key={c.id}>
                          <GraphWrapper active={currentGraph} component={c} toggleCollapse={this.toggleCollapse}>
                            <BeltBinarySrcGenGraphs
                              t={true}
                              binaryConfiguration={
                                this.props.detailsConfiguration?.[this.props.plant.plant ?? '']?.[
                                  decoratorNodeType ?? 'MDIR'
                                ].binaryMapping
                              }
                              component={c}
                              inverter={['SG2_PLANT_DEC'].includes(c.nodeType)}
                              measureActCurrentName={'_MT_DEC_ACT_CUR'}
                            />
                          </GraphWrapper>
                        </div>
                      )
                    case 'SG2_PLANT_BUFFER': {
                      return (
                        <div className="graph-wrapper" ref={e => (itemRefs[c.id] = e)} key={c.id}>
                          <GraphWrapper active={currentGraph} component={c} toggleCollapse={this.toggleCollapse}>
                            <BuffersFillGraph id={c.id} />
                          </GraphWrapper>
                        </div>
                      )
                    }
                    case 'PLANT_DATA_PRESS': {
                      return (
                        <div className="graph-wrapper" ref={e => (itemRefs[c.id] = e)} key={c.id}>
                          <GraphWrapper active={currentGraph} component={c} toggleCollapse={this.toggleCollapse}>
                            <PressDetail measureId={c.id} workShift={this.props.workshift} />
                          </GraphWrapper>
                        </div>
                      )
                    }
                    case 'PLANT_DATA_IMP_ASP': {
                      return (
                        <div className="graph-wrapper" ref={e => (itemRefs[c.id] = e)} key={c.id}>
                          <GraphWrapper active={currentGraph} component={c} toggleCollapse={this.toggleCollapse}>
                            <AspirationSystem measureId={c.id} workShift={this.props.workshift} />
                          </GraphWrapper>
                        </div>
                      )
                    }
                    default:
                      return null
                  }
                })}
            </React.Fragment>
          </div>
        </div>
      </div>
    )
  }

  private toggleCollapse(id: string) {
    this.setState({
      currentGraph: id && this.state.currentGraph.indexOf(id) > -1 ? [] : [id],
    })

    if (id && this.state.currentGraph.indexOf(id) === -1) {
      setTimeout(() => {
        // itemRefs[id].scrollIntoView()
        // window.scrollBy(0, -203)
      }, 300)
    }
  }

  private handleScroll() {
    const lastScrollY = window.scrollY

    if (!ticking) {
      window.requestAnimationFrame(() => {
        this.setState({
          lastScrollY,
        })
        ticking = false
      })

      ticking = true
    }
  }
}

export default withRouter<any, any>(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PlantAnalysis)))
