import moment from "moment"
import { getDerivedWordMeasures } from "../_measures/_derivedMeasures"
import { DerivedWordMeasures } from "../_measures/_bynaryMappings"
import { SupportedMeasures } from "../_measures/_measures"

type HydratedData = Array<Required<{time: number}>>

export interface WarnAreasData {
  slug: string
  measure: SupportedMeasures
  fn : (value: number) => boolean
  color?: string
}

const hydrateWarnAreas = (timedDatum: any, warnAreasData: WarnAreasData[], ) => {
    return warnAreasData?.reduce((acc, w) => {
        const warnData = w.fn(timedDatum[w.measure])
        return {
            ...acc,
            [w.slug] : warnData ? 100 : 0
        }
    },{})
}

export const hydrateData = (
    rawData: {[key: string]: Array<{x:number, y:number, h100: number}>},
    supportedDerivedWordMeasures: DerivedWordMeasures[],
    interval: [Date, Date],
    warnAreasData?: WarnAreasData[]
  ): HydratedData => {

    const initObject = Object.keys(rawData).reduce((acc,value) => ({
        ...acc,
        [value]: undefined
    }), {})

    const hydratedData: HydratedData = [{time: moment(interval[0]).unix()}, {time: moment(interval[1]).unix()}]

    Object.entries(rawData).map(([measure, measureData]) => {
        measureData.map((timedMeasureData) => {
            const index = hydratedData.findIndex(entry => entry.time === timedMeasureData.x)
            if(index === -1){
                hydratedData.push({...initObject, [measure]: timedMeasureData.y, time: timedMeasureData.x})
            }else{
                const updatedData = {...(hydratedData[index]), [measure]: timedMeasureData.y}
                hydratedData[index] = {...updatedData}
            }
        })
    })

    hydratedData.sort(( a, b ) => {
        if (a.time < b.time) {
          return -1
        }
        if (a.time > b.time) {
          return 1
        }
        return 0
    })


    /** Hydrate word measures for all timeline with the effective current status */
    hydratedData.forEach((values, index) => {
        const {time, ...others} = values
        const derivedData = getDerivedWordMeasures(supportedDerivedWordMeasures, values)
        if(index > 0){
            const prevValues = hydratedData[index - 1]
            const fixedOthersAndDerivedData = Object.entries({...others, ...derivedData}).reduce((acc, [key, value]) => {
                return {
                    ...acc,
                    [key]: value === undefined ? prevValues[key] : value
                }
            }, {})
            const hydratedWarnAreas = hydrateWarnAreas(fixedOthersAndDerivedData, warnAreasData ?? [])
            hydratedData[index] = {time, ...fixedOthersAndDerivedData, ...hydratedWarnAreas}
        }else{
            const hydratedWarnAreas = hydrateWarnAreas(others, warnAreasData ?? [])
            hydratedData[index] = {time, ...others, ...derivedData, ...hydratedWarnAreas}
        }
    })
    return hydratedData
  }